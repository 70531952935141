<template>
	<div style="max-width: 1100px;min-width: 1100px;margin: 0 auto;height: 100%;z-index: 10" class="padding-t-20">
		<div class="text-center margin-b-30">
			<p class="font-weight700 font-size32">全国销售网络</p>
			<p>强大的国内营销服务网络，遍布全国大中城市</p>
		</div>
		<div class="flex-row padding-15" style="background-color: #f1f7ff;" >
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="水泥仓库" name="first">
					<div id="container" v-loading="loading"></div>
				</el-tab-pane>
				<el-tab-pane label="商混仓库" name="second">
					<div id="container1" v-loading="loading"></div>
				</el-tab-pane>
				<el-tab-pane label="零售仓库" name="three">
					<div id="container2" v-loading="loading"></div>
				</el-tab-pane>
			</el-tabs>
			<div class="Area-box margin-l-15 flex-colum padding-10 bg-fff" style="margin-top: 40px;">
				<div class="flex-row-align-center margin-b-15">
					<span class="iconfont icon-wangdian font-size30 margin-r-10" style="color: #0173FE;"></span>
					<div>
						<p>区域查询</p>
						<p class="font-color-ccc">Network search</p>
					</div>
				</div>
				<el-select v-model="value" placeholder="请选择" style="width: 100%;" class="margin-b-10" @change="AreaChange">
					<el-option v-for="item in AreaList" :key="item.fGovernmenAreaID" :label="item.fGovernmenAreaName" :value="item.fGovernmenAreaID">
					</el-option>
				</el-select>
				<div class="flex-colum Depot-box" id="Depot" v-loading="DepotLoading">
					<div class="Depot-detail-box font-size12 border-bottom-F2F2F2 margin-b-5 padding-tb-5 pointer" v-for="(item,index) in DepotList"
					 :key="index" @click="DepotClick(item.flat, item.flng, 9, item.fDepotID)">
						<div>{{item.fDepotName}}</div>
						<div class="font-color-999 hide-text">{{item.fAddress}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../../config/config.json';
	export default {
		data() {
			return {
				activeName: 'first', //tab选择了那个first：水泥仓库second：商混仓库
				loading: false, //地图加载等待
				DepotLoading: false, //仓库加载等待
				AreaList: [], //水泥仓库区域列表
				DepotList: [], //水泥仓库列表
				value: '',
				marker: '',
				map: ''
			};
		},
		created() {
			// this.getCementDepot();
			this.loadScript();
			this.getBusinessmixDepot();
			this.retailDepot()
		},
		mounted() {
			// this.getCementArea();
		},
		methods: {
			loadScript() {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://map.qq.com/api/gljs?v=1.exp&key=" + config.TengxunMapKey;
				script.onload = script.onreadystatechange = () => {
					if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
						this.initOrUpdateCementMap(38.614831, 103.963313, 4);
						script.onload = script.onreadystatechange = null;
					}
				};
				document.body.appendChild(script);
			},
			//tab点击事件
			handleClick(val) {
				if (this.activeName == 'first') {
					this.value = '';
					this.getCementDepot();
					this.initOrUpdateCementMap(38.614831, 103.963313, 4);
					// this.getCementArea();
				}else if(this.activeName == 'second'){
					this.value = '';
					this.getBusinessmixDepot();
					this.initOrUpdateBusinessmixMap(38.614831, 103.963313, 4);
					// this.getBusinessmixArea();
				}else {
					console.log(12)
					this.value = '';
					this.retailDepot();
					this.initRetail(38.614831, 103.963313, 4);
					// this.retailArea();
				}
				
			},
			//选择区域事件
			AreaChange(val) {
				if (this.activeName == 'first') {
					this.getCementDepot(true);
				} else if(this.activeName == 'second'){
					this.getBusinessmixDepot(true);
				}else {
					this.retailDepot(true)
				}
				
			},
			//仓库选择事件
			DepotClick(centerflat, centerflng, zoom, styleId) {
				if (this.activeName == 'first') {
					this.initOrUpdateCementMap(centerflat, centerflng, zoom, styleId);
				} else if (this.activeName == 'second'){
					this.initOrUpdateBusinessmixMap(centerflat, centerflng, zoom, styleId);
				}else {
					this.initRetail(centerflat, centerflng, zoom, styleId);
				}
				
			},
			//获取水泥仓库区域
			getCementArea() {
				const CementArea = sessionStorage.getItem('CementArea')
				if (CementArea) {

					this.AreaList = [];
					CementArea.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
				} else {
					this.DepotLoading = true
					this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-cement-deport', {
						fRegion: ''
					}).then(res => {
						this.DepotLoading = false
						sessionStorage.setItem('CementArea', JSON.stringify(res))
						this.AreaList = [];
						res.obj.fGovernmenIDs.forEach(item => {
							if (item.fGovernmenAreaID != -1) {
								this.AreaList.push(item);
							}
						});
						this.AreaList.unshift({
							fGovernmenAreaID: '',
							fGovernmenAreaName: '全部'
						});
						this.DepotList = res.obj.fDepotList;
						document.getElementById('Depot').scrollTop = 0;
					}, error => {

					});
				}
				
			},
			// //获取水泥仓库
			getCementDepot(val) {
				const CementArea = JSON.parse(sessionStorage.getItem('CementArea'))
				if (CementArea) {
					this.AreaList = [];
					if (this.value !== '') {
						this.DepotList = CementArea.obj.fDepotList.filter(item => {
							return item.fRegion == this.value
						})
					} else {
						this.DepotList = CementArea.obj.fDepotList
					}
					CementArea.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
					document.getElementById('Depot').scrollTop = 0;
				} else {
					this.DepotLoading = true
					this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-cement-deport', {
						fRegion: ''
					}).then(res => {
						this.DepotLoading = false;
						c
						this.AreaList = [];
						res.obj.fGovernmenIDs.forEach(item => {
							if (item.fGovernmenAreaID != -1) {
								this.AreaList.push(item);
							}
						});
						this.AreaList.unshift({
							fGovernmenAreaID: '',
							fGovernmenAreaName: '全部'
						});
						this.DepotList = res.obj.fDepotList;
						document.getElementById('Depot').scrollTop = 0;
					}, error => {
						if (val) {
							this.DepotLoading = false;
						}
					});
				}
				// this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-cement-deport', {
				// 	fRegion: this.value
				// }).then(res => {
				// 	if (val) {
				// 		this.DepotLoading = false;
				// 	}
				// 	this.DepotList = res.obj.fDepotList;
				// 	document.getElementById('Depot').scrollTop = 0;
				// }, error => {
				// 	if (val) {
				// 		this.DepotLoading = false;
				// 	}
				// });
			},
			//初始化或者更新水泥仓库地图
			initOrUpdateCementMap(centerflat, centerflng, zoom, styleId) {
				this.loading = true;
				console.log('this.marker', this.marker);
				let that = this;
				//如果地图存在，就销毁，下面会重新创建一个
				if (that.map) {
					that.map.destroy();
				}
				const CementArea = JSON.parse(sessionStorage.getItem('CementArea'))
				if (CementArea) {
          this.AreaList = [];
					CementArea.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
          if (this.value !== '') {
						this.DepotList = CementArea.obj.fDepotList.filter(item => {
							return item.fRegion == this.value
						})
					} else {
						this.DepotList = CementArea.obj.fDepotList
					}
					// this.DepotList = CementArea.obj.fDepotList;
					document.getElementById('Depot').scrollTop = 0;
					let geometries = [];
					geometries = CementArea.obj.fDepotList;
					geometries.forEach((item, index) => {
						item.position = new TMap.LatLng(item.flat, item.flng);
						item.styleId = 'marker';
						item.id = item.fDepotID;
						if (styleId == item.fDepotID) {
							item.styleId = styleId
						}
					});
					console.log('styleId', styleId);
					let styles = {};
					if (styleId) {
						styles = {
							"marker": new TMap.MarkerStyle({
								"width": 20,
								"height": 30,
								"anchor": {
									x: 12,
									y: 35
								},
								// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							}),
							[styleId]: new TMap.MarkerStyle({
								"width": 24,
								"height": 36,
								"anchor": {
									x: 12,
									y: 35
								},
								"src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							})
						};
					} else {
						styles = {
							"marker": new TMap.MarkerStyle({
								"width": 24,
								"height": 35,
								"anchor": {
									x: 12,
									y: 35
								},
								// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							})
						};
					}
					console.log('styles', styles);

					this.$nextTick(() => {
						var center = new TMap.LatLng(centerflat, centerflng); //设置中心点坐标
						//初始化地图
						this.map = new TMap.Map("container", {
							center: center,
							zoom: zoom, //设置地图缩放级别
							pitch: 0, //设置俯仰角
							rotation: 0, //设置地图旋转角度
						});

						//初始marker
						this.marker = new TMap.MultiMarker({
							id: 'marker-layer',
							map: this.map,
							styles: styles,
							geometries: geometries
						});
						//初始化infoWindow
						var infoWindow = new TMap.InfoWindow({
							map: this.map,
							position: new TMap.LatLng(38.614831, 103.963313),
							offset: {
								x: 0,
								y: -32
							} //设置信息窗相对position偏移像素
						});
						infoWindow.close(); //初始关闭信息窗关闭
						//监听标注点击事件
						this.marker.on("click", function(evt) {
							console.log('evt', evt);
							//设置infoWindow
							infoWindow.open(); //打开信息窗
							infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
							infoWindow.setContent(evt.geometry.fRichText); //设置信息窗内容
						})
					});
					that.loading = false;
					return
				}
				this.DepotLoading = true
				this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-cement-deport', {
					fRegion: ''
				}).then(res => {
					this.DepotLoading = false
					sessionStorage.setItem('CementArea', JSON.stringify(res))
          this.AreaList = [];
						res.obj.fGovernmenIDs.forEach(item => {
							if (item.fGovernmenAreaID != -1) {
								this.AreaList.push(item);
							}
						});
						this.AreaList.unshift({
							fGovernmenAreaID: '',
							fGovernmenAreaName: '全部'
						});
						this.DepotList = res.obj.fDepotList;
						document.getElementById('Depot').scrollTop = 0;
					let geometries = [];
					geometries = res.obj.fDepotList;
					geometries.forEach((item, index) => {
						item.position = new TMap.LatLng(item.flat, item.flng);
						item.styleId = 'marker';
						item.id = item.fDepotID;
						if (styleId == item.fDepotID) {
							item.styleId = styleId
						}
					});
					console.log('styleId', styleId);
					let styles = {};
					if (styleId) {
						styles = {
							"marker": new TMap.MarkerStyle({
								"width": 20,
								"height": 30,
								"anchor": {
									x: 12,
									y: 35
								},
								// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							}),
							[styleId]: new TMap.MarkerStyle({
								"width": 24,
								"height": 36,
								"anchor": {
									x: 12,
									y: 35
								},
								"src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							})
						};
					} else {
						styles = {
							"marker": new TMap.MarkerStyle({
								"width": 24,
								"height": 35,
								"anchor": {
									x: 12,
									y: 35
								},
								// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
							})
						};
					}
					console.log('styles', styles);

					this.$nextTick(() => {
						var center = new TMap.LatLng(centerflat, centerflng); //设置中心点坐标
						//初始化地图
						this.map = new TMap.Map("container", {
							center: center,
							zoom: zoom, //设置地图缩放级别
							pitch: 0, //设置俯仰角
							rotation: 0, //设置地图旋转角度
						});

						//初始marker
						this.marker = new TMap.MultiMarker({
							id: 'marker-layer',
							map: this.map,
							styles: styles,
							geometries: geometries
						});
						//初始化infoWindow
						var infoWindow = new TMap.InfoWindow({
							map: this.map,
							position: new TMap.LatLng(38.614831, 103.963313),
							offset: {
								x: 0,
								y: -32
							} //设置信息窗相对position偏移像素
						});
						infoWindow.close(); //初始关闭信息窗关闭
						//监听标注点击事件
						this.marker.on("click", function(evt) {
							console.log('evt', evt);
							//设置infoWindow
							infoWindow.open(); //打开信息窗
							infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
							infoWindow.setContent(evt.geometry.fRichText); //设置信息窗内容
						})
					});
					
					that.loading = false;
				}, error => {
					that.loading = false;
				});
			},

			//获取商混仓库区域
			getBusinessmixArea() {
				this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-businessmix-deport', {
					fRegion: ''
				}).then(res => {
					this.AreaList = [];
					res.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
				}, error => {

				});
			},
			//获取零售仓库区域
			retailArea() {
				this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-retail-deport', {
					fRegion: ''
				}).then(res => {
					this.AreaList = [];
					res.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
          console.log(this.AreaList,'零售商')
				}, error => {
			
				});
			},
			//获取商混仓库
			getBusinessmixDepot(val) {
				const BusinessmixDepot = JSON.parse(sessionStorage.getItem('BusinessmixDepot'))
				if (BusinessmixDepot) {
					this.AreaList = [];
					if (this.value !== '') {
						this.DepotList = BusinessmixDepot.obj.fDepotList.filter(item => {
							return item.fRegion == this.value
						})
					} else {
						this.DepotList = BusinessmixDepot.obj.fDepotList
					}
					BusinessmixDepot.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
					document.getElementById('Depot').scrollTop = 0;
				} else {
					this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-businessmix-deport', {
						fRegion: this.value
					}).then(res => {
						if (val) {
							this.DepotLoading = false;
						}
						sessionStorage.setItem('BusinessmixDepot', JSON.stringify(res))
						this.AreaList = [];
						res.obj.fGovernmenIDs.forEach(item => {
							if (item.fGovernmenAreaID != -1) {
								this.AreaList.push(item);
							}
						});
						this.AreaList.unshift({
							fGovernmenAreaID: '',
							fGovernmenAreaName: '全部'
						});
						this.DepotList = res.obj.fDepotList;
						console.log('this.DepotList', this.DepotList);
						document.getElementById('Depot').scrollTop = 0;
					}, error => {
						if (val) {
							this.DepotLoading = false;
						}
					});
				}
			},
			//获取零售仓库
			retailDepot(val){
				const retailDepot = JSON.parse(sessionStorage.getItem('retailDepot'))
        // alert(retailDepot)
        console.log(retailDepot,'retailDepot')
				if (retailDepot) {
          console.log(1111)
					this.AreaList = [];
          console.log(this.value)
					if (this.value !== '') {
						this.DepotList = retailDepot.obj.fDepotList.filter(item => {
							return item.fRegion == this.value
						})
            console.log(this.DepotList,'新疆')
					} else {
						this.DepotList = retailDepot.obj.fDepotList
					}
					retailDepot.obj.fGovernmenIDs.forEach(item => {
						if (item.fGovernmenAreaID != -1) {
							this.AreaList.push(item);
						}
					});
					this.AreaList.unshift({
						fGovernmenAreaID: '',
						fGovernmenAreaName: '全部'
					});
					document.getElementById('Depot').scrollTop = 0;
				} else {
					this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-retail-deport', {
						fRegion: this.value
					}).then(res => {
						if (val) {
							this.DepotLoading = false;
						}
						sessionStorage.setItem('retailDepot', JSON.stringify(res))
						this.AreaList = [];
						res.obj.fGovernmenIDs.forEach(item => {
							if (item.fGovernmenAreaID != -1) {
								this.AreaList.push(item);
							}
						});
						this.AreaList.unshift({
							fGovernmenAreaID: '',
							fGovernmenAreaName: '全部'
						});
						this.DepotList = res.obj.fDepotList;
						console.log('this.DepotList', this.DepotList);
						document.getElementById('Depot').scrollTop = 0;
					}, error => {
						if (val) {
							this.DepotLoading = false;
						}
					});
				}
			},
			//初始化或者零售仓库地图
			initRetail(centerflat, centerflng, zoom, styleId){
				this.loading = true;
					console.log('this.marker', this.marker);
					let that = this;
					//如果地图存在，就销毁，下面会重新创建一个
					if (that.map) {
						that.map.destroy();
					}
					// this.ApiRequestPostNOMess('api/mall/ebshop/depot-info/get-retail-deport', {
					// 	fRegion: ''
					// }).then(res => {
						const retailDepot = JSON.parse(sessionStorage.getItem('retailDepot'))
						let geometries = [];
						geometries = retailDepot.obj.fDepotList;
						geometries.forEach((item, index) => {
							item.position = new TMap.LatLng(item.flat, item.flng);
							item.styleId = 'marker';
							item.id = item.fDepotID;
							if (styleId == item.fDepotID) {
								item.styleId = styleId
							}
						});
						console.log('styleId', styleId);
						let styles = {};
						if (styleId) {
							styles = {
								"marker": new TMap.MarkerStyle({
									"width": 20,
									"height": 30,
									"anchor": {
										x: 12,
										y: 35
									},
									// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								}),
								[styleId]: new TMap.MarkerStyle({
									"width": 24,
									"height": 36,
									"anchor": {
										x: 12,
										y: 35
									},
									"src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								})
							};
						} else {
							styles = {
								"marker": new TMap.MarkerStyle({
									"width": 24,
									"height": 35,
									"anchor": {
										x: 12,
										y: 35
									},
									// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								})
							};
						}
						console.log('styles', styles);
				
						this.$nextTick(() => {
							var center = new TMap.LatLng(centerflat, centerflng); //设置中心点坐标
							//初始化地图
							this.map = new TMap.Map("container2", {
								center: center,
								zoom: zoom, //设置地图缩放级别
								pitch: 0, //设置俯仰角
								rotation: 0, //设置地图旋转角度
							});
				
							//初始marker
							this.marker = new TMap.MultiMarker({
								id: 'marker-layer',
								map: this.map,
								styles: styles,
								geometries: geometries
							});
							//初始化infoWindow
							var infoWindow = new TMap.InfoWindow({
								map: this.map,
								position: new TMap.LatLng(38.614831, 103.963313),
								offset: {
									x: 0,
									y: -32
								} //设置信息窗相对position偏移像素
							});
							infoWindow.close(); //初始关闭信息窗关闭
							//监听标注点击事件
							this.marker.on("click", function(evt) {
								console.log('evt', evt);
								//设置infoWindow
								infoWindow.open(); //打开信息窗
								infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
								infoWindow.setContent(evt.geometry.fRichText); //设置信息窗内容
							})
						});
						that.loading = false;
					// }, error => {
					// 	that.loading = false;
					// });
				},
			//初始化或者更新商混仓库地图
			initOrUpdateBusinessmixMap(centerflat, centerflng, zoom, styleId) {
					this.loading = true;
					console.log('this.marker', this.marker);
					let that = this;
					//如果地图存在，就销毁，下面会重新创建一个
					if (that.map) {
						that.map.destroy();
					}
						const BusinessmixDepot = JSON.parse(sessionStorage.getItem('BusinessmixDepot'))
						if (!BusinessmixDepot) {
							this.getBusinessmixDepot()
						}
						// console.log('获取商混仓库及其可选区域', res);
						let geometries = [];
						geometries = BusinessmixDepot.obj.fDepotList;
						geometries.forEach((item, index) => {
							item.position = new TMap.LatLng(item.flat, item.flng);
							item.styleId = 'marker';
							item.id = item.fDepotID;
							if (styleId == item.fDepotID) {
								item.styleId = styleId
							}
						});
						console.log('styleId', styleId);
						let styles = {};
						if (styleId) {
							styles = {
								"marker": new TMap.MarkerStyle({
									"width": 20,
									"height": 30,
									"anchor": {
										x: 12,
										y: 35
									},
									// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								}),
								[styleId]: new TMap.MarkerStyle({
									"width": 24,
									"height": 36,
									"anchor": {
										x: 12,
										y: 35
									},
									"src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								})
							};
						} else {
							styles = {
								"marker": new TMap.MarkerStyle({
									"width": 24,
									"height": 35,
									"anchor": {
										x: 12,
										y: 35
									},
									// "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
								})
							};
						}
						console.log('styles', styles);
				
						this.$nextTick(() => {
							var center = new TMap.LatLng(centerflat, centerflng); //设置中心点坐标
							//初始化地图
							this.map = new TMap.Map("container1", {
								center: center,
								zoom: zoom, //设置地图缩放级别
								pitch: 0, //设置俯仰角
								rotation: 0, //设置地图旋转角度
							});
				
							//初始marker
							this.marker = new TMap.MultiMarker({
								id: 'marker-layer',
								map: this.map,
								styles: styles,
								geometries: geometries
							});
							//初始化infoWindow
							var infoWindow = new TMap.InfoWindow({
								map: this.map,
								position: new TMap.LatLng(38.614831, 103.963313),
								offset: {
									x: 0,
									y: -32
								} //设置信息窗相对position偏移像素
							});
							infoWindow.close(); //初始关闭信息窗关闭
							//监听标注点击事件
							this.marker.on("click", function(evt) {
								console.log('evt', evt);
								//设置infoWindow
								infoWindow.open(); //打开信息窗
								infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
								infoWindow.setContent(evt.geometry.fRichText); //设置信息窗内容
							})
						});
						that.loading = false;
					// }, error => {
					// 	that.loading = false;
					// });
			},
			}
		}
</script>

<style lang="scss" scoped>
	#container {
		width: 711px;
		height: 550px;
	}
  
	#container1 {
		width: 711px;
		height: 550px;
	}
	#container2{
		width: 711px;
		height: 550px;
	}
	.Area-box {
		width: 324px;
		height: 544px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	}

	/deep/ .el-select {
		.el-input__icon {
			line-height: 0;
		}
	}

	.Depot-box {
		height: 450px;
		overflow-y: auto;
	}

	.Depot-detail-box {
		height: 33px;
	}
</style>


<!-- <template>
	<div id="container"></div>
</template>
<script>
	export default {
		name: "Tendmap",
		data() {
			return {
				modelMsg: false, //显示模态框
				address: "", //输入的地址
				mapList: [], //画图地址
				mapVal: "", //创建的地图，赋值用
				colors: ['#72A1A2', '#56564C', '#3593C9', '#075279', '#F37B84', '#E27B2D', '#9B4401', '#B481B3', '#C59C42',
					'#EFDDAF'
				],
				url: "https://apis.map.qq.com/ws/district/v1/search",
				queryParams: {
					keyword: "110000",
					get_polygon: 2,
					key: "PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S",
					output: "jsonp",
				},
			};
		},
		created() {
			this.initMap(32.018555, 112.155652);
		},
		methods: {
			//初始化地图
			initMap(lat, lng) {
				let that = this;
				//如果地图存在，就销毁，下面会重新创建一个
				if (that.mapVal) {
					that.mapVal.destroy();
				}
				//定义地图中心点坐标
				this.$nextTick(() => {
					var center = new TMap.LatLng(lat, lng);
					//初始化地图
					var map = new TMap.Map("container", {
						center: center, //设置地图中心点坐标
						zoom: 4, //设置地图缩放级别
						pitch: 0, //设置俯仰角
						rotation: 0, //设置地图旋转角度
						viewMode: "2D",
					});
					var ps = []; // 边界范围
					var colors = that.colors; //颜色
					that
						.$jsonp(that.url, that.queryParams)
						.then((res) => {
							console.log('that.queryParams',that.queryParams);
							console.log('res',res);
							var resData = res.result[0];
							for (var a = 0; a < resData.length; a++) {
								console.log(resData);
								var resDataPolygon = resData[a].polygon[0];
								var path = [];
								for (var b = 0; b < resDataPolygon.length; b = b + 2) {
									path.push(
										new TMap.LatLng(resDataPolygon[b + 1], resDataPolygon[b])
									);
								}
								ps.push(path);
							}

							//初始化polygon--------
							var polygon = new TMap.MultiPolygon({
								map, // 显示多边形图层的底图
								styles: {
									// 多边形的相关样式
									polygon0: new TMap.PolygonStyle({
										color: colors[0], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon1: new TMap.PolygonStyle({
										color: colors[1], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon2: new TMap.PolygonStyle({
										color: colors[2], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon3: new TMap.PolygonStyle({
										color: colors[3], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon4: new TMap.PolygonStyle({
										color: colors[4], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon5: new TMap.PolygonStyle({
										color: colors[5], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon6: new TMap.PolygonStyle({
										color: colors[6], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon7: new TMap.PolygonStyle({
										color: colors[7], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
									polygon8: new TMap.PolygonStyle({
										color: colors[8], // 面填充色
										showBorder: true, // 是否显示拔起面的边线
										borderColor: "rgba(255,255,255,255)", // 边线颜色
										borderWidth: 3, // 边线宽度
										borderDashArray: [5, 5], // 虚线数组
									}),
								},
								geometries: [{
										id: "polygon0", // 多边形图形数据的标志信息
										styleId: "polygon0", // 样式id
										paths: ps[0], // 多边形的位置信息
									},
									{
										id: "polygon1", // 多边形图形数据的标志信息
										styleId: "polygon1", // 样式id
										paths: ps[1], // 多边形的位置信息
									},
									{
										id: "polygon2", // 多边形图形数据的标志信息
										styleId: "polygon2", // 样式id
										paths: ps[2], // 多边形的位置信息
									},
									{
										id: "polygon3", // 多边形图形数据的标志信息
										styleId: "polygon3", // 样式id
										paths: ps[3], // 多边形的位置信息
									},
									{
										id: "polygon4", // 多边形图形数据的标志信息
										styleId: "polygon4", // 样式id
										paths: ps[4], // 多边形的位置信息
									},
									{
										id: "polygon5", // 多边形图形数据的标志信息
										styleId: "polygon5", // 样式id
										paths: ps[5], // 多边形的位置信息
									},
									{
										id: "polygon6", // 多边形图形数据的标志信息
										styleId: "polygon6", // 样式id
										paths: ps[6], // 多边形的位置信息
									},
									{
										id: "polygon7", // 多边形图形数据的标志信息
										styleId: "polygon7", // 样式id
										paths: ps[7], // 多边形的位置信息
									},
									{
										id: "polygon8", // 多边形图形数据的标志信息
										styleId: "polygon8", // 样式id
										paths: ps[8], // 多边形的位置信息
									},
								],
							});
							//  ------
						})
						.catch((err) => {
							console.log(err);
						});
				});

			},
		},
	};
</script>

<style lang="scss" scoped>
	#container {
		width: 100%;
		height: 100%;
	}
</style> -->
